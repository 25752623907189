import React from 'react'
import Footer from '../components/Footer'

const Aboutus = () => {
  return (
    < >Aboutus
    <Footer />
    </ >

  )
}

export default Aboutus